<template>
  <div class="flex flex-col mb-10">

    <div style="margin-left: -10px; padding:30px; border: 1px solid #8080807a; border-radius: 25px; border-radius: 5px;">
        <div class="md-layout">
            <Content textColor="black" labelClass="text-gray-900" label="Name of The Course" class="my-3 text-uppercase w-full"  :text="data.name" /> 
            <div class="course-card ">
                <div class="bg-primary rounded">
                    <h2 class=" text-base  pt-5 px-2 text-center text-gray-600">{{data.name}}</h2>
                    <div class="my-6 text-center">
                        <span class="discount-price text-gray-600">$ {{data.package_detail.old_price}} </span>
                        <h2 class="my-4 text-4xl font-bold text-white">$ {{data.package_detail.new_price}} </h2>
                    </div>
                </div>
                <div class="featur-list">   
                  <ul>
                    <li v-if="data.package_detail.is_pte_practice" class="font-bold">Number of PTE Practice <span style="margin-left:90px">{{data.package_detail.pte_practice}}</span></li> 
                    <li v-if="data.package_detail.is_practice_test" class="font-bold">Number of Practice Test <span style="margin-left:89px">{{data.package_detail.practice_test}}</span></li> 
                    <li v-if="data.package_detail.is_mock_test" class="font-bold">Number of Mock Test <span style="margin-left:105px">{{data.package_detail.mock_test}}</span></li> 
                    <li v-if="data.package_detail.is_quiz"  class="font-bold">Number of Quiz <span style="margin-left:140px">{{data.package_detail.quiz_test}}</span></li> 
                    <li v-if="data.package_detail.is_materials" class="font-bold">Materials<span style="margin-left:181px">{{data.package_detail.material_test}}</span></li> 
                    <li v-if="data.package_detail.is_live_class" class="font-bold">Live Class<span style="margin-left:179px">{{data.package_detail.live_classes}}</span></li> 
                    <li v-if="data.package_detail.is_webinars" class="font-bold">Webinars <span style="margin-left:178px">{{data.package_detail.webinars}}</span></li> 
                    <li v-if="data.package_detail.is_one_to_one_appointment" class="font-bold">One To One Appointment <span style="margin-left:82px">{{data.package_detail.OneToOneAppointment}}</span></li> 
                  </ul>
                </div>
                <div class="my-6 text-center">
                  <p class="text-uppercase inline-block font-semibold text-underline text-primary pointer">View Details</p>
                  <Button
                      class="bg-white rounded text-victoria w-full ml-0 font-bold" style="border: 1px solid;"
                      label="Select"
                      type="button"
                      @click="submit"
                  >
                  </Button>
                </div>
            </div>
        </div>
        <div class="flex justify-end mt-4">
            <Button
                :loading="loading"
                class="bg-victoria rounded text-white mr-0"
                label="Save"
                type="button"
                @click="submit"
            >
            </Button>
        </div> 
    </div>   
  </div>
</template>

<script>
import {
  Button,
} from "@/components";
import Content from "@/components/atom/Content";
import { mapMutations, mapActions } from "vuex";
import { renameObject, renameFile } from "@/store/upload";

export default {
  components: {
    Button,
    Content 
  },

  data() {
    return {
      loading: false,
      data:''
    }
  },
  computed:{
  
   },
   methods:{
     ...mapMutations({
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actSavePackage: "registration/actSavePackage",
      actUpdatePackage: "registration/actUpdatePackage"
    }),

    submit() {
      
     this.actSavePackage(this.data)
      .then(({ message, package_id }) => {


        let thumbnail = renameFile(this.data.thumbnail,this.data.type+'_thumbnail_'+package_id);

        let formData = this.getLocal('course-create-info');
        formData.thumbnail = thumbnail;
        this.setLocal('course-create-info', formData);

        renameObject(this.data.thumbnail, thumbnail);

        this.actUpdatePackage({
          packageId: package_id,
          payload: {...formData}
        });
        this.remove('course-create-menu');
        this.remove('course-create-info');
        this.setShowSnackbar(message);
        this.$router.push({ name: 'courses' });
      })

    },

    clearAll() {
      this.$emit("clear-all", "");
    }
   },

  created() {
    let existData = this.getLocal('course-create-info');
    if (existData) this.data = existData;    
  }
}
</script>